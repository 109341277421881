import axios from '../../config/axios';
import live_display_socket from "../../config/live_display_socket";
import { useEffect, useRef, useState } from "react";
import RTVSelector from "../RTVSelector";
import moment from 'moment-timezone';
import { autoRestartTime, terraceOpenTime } from "../../config/terrace";

const BottomBar = ({device}) => {

    const [showResortSelector, setShowResortSelector] = useState(false);
    const [showTimes, setShowTimes] = useState([]);

    const buttonStyle = {
        marginLeft: '10px',
        border: '2px solid white',
        background: 'transparent',
        color: 'white',
        fontFamily: 'Lato',
        fontSize: '40px',
        padding: '0px 20px',
        borderRadius: '20px',
        fontWeight: '700'
    }


    const updateDeviceView = (view) => {
        axios
            .patch(`/users/device/${device._id}`, {screen_view: view}, {withCredentials: true})
            .then(() => {
                live_display_socket.emit('remote_refresh', {
                    device_id: device?._id,
                });
            })
            .catch((error) => {

            });
    };

    const switchToParks = () => {
        axios
            .get(`/parks/public/${device._id}`, {withCredentials: true})
            .then((response) => {
                if (response.data.parks.length > 0) {
                    updateDeviceView('Ride__' + response.data.parks[0].park_code + '_1');
                }
            })
            .catch((error) => {
            });
    }

    const resortAudioRef = useRef();

    // resort music controller
    useEffect(() => {

        if (device.screen_view.includes('RTV')) {
            resortAudioRef.current.volume = localStorage.getItem('volume');
            resortAudioRef.current.play();
        } else {
            resortAudioRef.current.pause();
        }


    }, [device, resortAudioRef]);


    // get show time
    const getShowTime = () =>{
        axios
            .get('/shows', {withCredentials: true})
            .then((response) => {
                setShowTimes(response.data.shows);
            })
            .catch((error) => {
                // console.log('error', error);
            });
    }

    const backgroundAudioRef = useRef();
    const announcementAudioRef = useRef();

    // stream announcement and sound controller
    useEffect(() => {

        // console.log('TRC Settings: ', device.announcement, device.trc_music)

        announcementAudioRef.current.volume = Math.min((+localStorage.getItem('volume') || 0.3) + 0.2, 1);

        if (device.screen_view === 'TRC' && device.trc_music) {
            // console.log('play background sound - trc');
            backgroundAudioRef.current.volume = localStorage.getItem('volume') || 0.3;
            backgroundAudioRef.current.play();
        } else {
            // console.log('pause background sound - trc');
            backgroundAudioRef.current.pause();
        }

        // Check every minute
        const intervalId = setInterval(() => {
            const currentTime = moment().tz('America/New_York'); // EST timezone

            // const fifteenMinutesBeforeShowTime1 = moment(showTimes[0]?.startTime).tz('America/New_York').subtract(15, 'minutes');
            // const fifteenMinutesBeforeShowTime2 = moment(showTimes[1]?.startTime).tz('America/New_York').subtract(15, 'minutes');
            //
            // // console.log(fifteenMinutesBeforeShowTime1.format('HH:mm'), fifteenMinutesBeforeShowTime2.format('HH.mm'), currentTime.format('HH:mm'));
            //
            // // Check if it's 8:45 AM
            // if ((currentTime.format('HH:mm') === fifteenMinutesBeforeShowTime1.format('HH:mm') || currentTime.format('HH:mm') === fifteenMinutesBeforeShowTime2.format('HH:mm')) && device.announcement) {
            //     announcementAudioRef.current.play();
            // }

            if(currentTime.format('HH:mm') === terraceOpenTime && device.announcement){
                // console.log('play announcement');
                announcementAudioRef.current.play();
            }

            // if current minute is 0, get show time
            if(currentTime.format('mm') === '00'){
                getShowTime();
            }
            // console.log(currentTime.format('HH:mm'))
            if(currentTime.format('HH:mm') === autoRestartTime) {
                sessionStorage.setItem('memClear', 'yes');
                window.location.reload();
            }
        }, 60000); // 60000ms = 1 minute

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [device, backgroundAudioRef, announcementAudioRef, showTimes]);


    return (
        <div>
            <audio src="https://wait-signs-static.s3.amazonaws.com/reosrt-music.mp3" ref={resortAudioRef}
                   loop={true}></audio>
            <audio src="https://wait-signs-static.s3.amazonaws.com/bacgroundLIVE.mp3" ref={backgroundAudioRef}
                   loop={true}></audio>
            <audio  src="https://wait-signs-static.s3.amazonaws.com/FWVO.wav" ref={announcementAudioRef}></audio>
            <div style={{
                position: 'fixed',
                bottom: '1.5%',
                display: 'flex',
                justifyContent: 'space-around',
                width: '100vw',
                zIndex: 110
            }}>
                <button
                    onClick={() => updateDeviceView('Home')}
                    style={{
                        ...buttonStyle,
                        color: device.screen_view === 'Home' ? '#3ADF3C' : 'white',
                        border: 'none'
                    }}
                >
                    {
                        device.screen_view === 'Home' ?
                            <img src="/static/home-green.png" alt="" style={{width: '60px'}}/> :
                            <img src="/static/home-white.png" alt="" style={{width: '60px'}}/>
                    }
                </button>
                <button
                    onClick={() => setShowResortSelector(true)}
                    style={{
                        ...buttonStyle,
                        color: device.screen_view.includes('RTV') ? '#3ADF3C' : 'white',
                        borderColor: device.screen_view.includes('RTV') ? '#3ADF3C' : 'white',
                    }}>
                    RESORT
                </button>
                <button
                    onClick={() => updateDeviceView('TRC')}
                    style={{
                        ...buttonStyle,
                        color: device.screen_view === 'TRC' ? '#3ADF3C' : 'white',
                        borderColor: device.screen_view === 'TRC' ? '#3ADF3C' : 'white',
                    }}
                >
                    TERRACE
                </button>

                <button
                    onClick={() => switchToParks()}
                    style={{
                        ...buttonStyle,
                        color: device.screen_view.includes('Ride') ? '#3ADF3C' : 'white',
                        borderColor: device.screen_view.includes('Ride') ? '#3ADF3C' : 'white',
                    }}>PARKS
                </button>
                <button
                    onClick={() => updateDeviceView('VC')}
                    style={{
                        ...buttonStyle,
                        color: device.screen_view.includes('VC') ? '#3ADF3C' : 'white',
                        borderColor: device.screen_view.includes('VC') ? '#3ADF3C' : 'white',
                    }}>VACATION
                </button>
            </div>
            <img src="/static/frame-bottom.png" alt=""
                 style={{position: 'fixed', bottom: '0', left: '0', width: '100vw', zIndex: 100}}/>

            {
                showResortSelector && <RTVSelector device={device} setShowResortSelector={setShowResortSelector}/>
            }
        </div>
    );
};

export default BottomBar;
