import { IoCloseSharp } from "react-icons/io5";
import axios from "../../config/axios";
import live_display_socket from "../../config/live_display_socket";

const ParkChangeModal = ({device, setShowParkChange, parks}) => {


    const updateDeviceView = (park_code) => {

        const view = 'Ride__' + park_code + '_1';
        setShowParkChange(false);
        axios
            .patch(`/users/device/${device._id}`, {screen_view: view}, {withCredentials: true})
            .then(() => {
                live_display_socket.emit('remote_refresh', {
                    device_id: device?._id,
                });
            })
            .catch((error) => {

            });
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '0%',
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
            zIndex: 50,
            opacity: '80%'
        }}>
            <div style={{position: 'absolute', top: '5%', right: '3%'}}
                 onClick={() => setShowParkChange(false)}>
                <IoCloseSharp size={60}/>
            </div>
            {
                parks.map((park, index) => {
                    return <div
                        key={index}
                        style={{
                            marginLeft: '10px',
                            border: '2px solid blue',
                            background: 'transparent',
                            color: 'white',
                            fontFamily: 'Lato',
                            fontSize: '40px',
                            padding: '0px 20px',
                            borderRadius: '20px',
                            fontWeight: '700',
                            margin: '10px 0px'
                        }}
                        onClick={() => updateDeviceView(park.park_code)}
                    >
                              <span style={{
                                  fontFamily: 'Lato',
                                  fontWeight: '700',
                                  color: 'blue'
                              }}>{park.park_name}</span>
                    </div>
                })
            }
        </div>
    );
};

export default ParkChangeModal;