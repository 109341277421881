import React, { useEffect, useState } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import axios from "../config/axios";
import live_display_socket from "../config/live_display_socket";


const orlando_resort_parks = [
    {
        park_name: 'Magic Kingdom',
        park_code: 'MK',
    },
    {
        park_name: 'Hollywood Studios',
        park_code: 'HS',
    },
    {
        park_name: 'Animal Kingdom',
        park_code: 'AK',
    },
    {
        park_name: 'EPCOT',
        park_code: 'EPCOT',
    },
];

const anaheim_resort_parks = [
    {
        park_name: 'Disneyland',
        park_code: 'DL',
    },
    {
        park_name: 'California Adventure',
        park_code: 'CA',
    },
];


const RtvSelector = ({device, setShowResortSelector}) => {

    const [parks, setParks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`/parks/public/${device._id}`, {withCredentials: true})
            .then((response) => {

                setParks(response.data.parks);
                setLoading(false);

            })
            .catch((error) => {
                // console.log(error)
            });
    }, []);

    useEffect(() => {
        if (parks.length > 0) {
            if (getListOfResorts().length === 1) {
                setShowResortSelector(false);
                updateDeviceView('RTV__' + getListOfResorts()[0]);
            }
        }
    }, [parks]);


    const getListOfResorts = () => {
        const resorts = [];
        if (showAnaheimResortButton()) {
            resorts.push('Anaheim');
        }
        if (showOrlandoResortButton()) {
            resorts.push('Orlando');
        }
        return resorts;
    };


    const showAnaheimResortButton = () => {
        // if parks contains any of the anaheim_resort_parks return true. else false
        return parks.some((park) => anaheim_resort_parks.some((anaheim_park) => anaheim_park.park_code === park.park_code));
    };

    const showOrlandoResortButton = () => {
        // if parks contains any of the orlando_resort_parks return true. else false
        return parks.some((park) => orlando_resort_parks.some((orlando_park) => orlando_park.park_code === park.park_code));
    };


    const updateDeviceView = (view) => {
        axios
            .patch(`/users/device/${device._id}`, {screen_view: view}, {withCredentials: true})
            .then(() => {
                live_display_socket.emit('remote_refresh', {
                    device_id: device?._id,
                });
            })
            .catch((error) => {

            });

    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '0%',
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
            zIndex: 50,
            opacity: '80%'
        }}>
            <div style={{position: 'absolute', top: '5%', right: '3%'}}
                 onClick={() => setShowResortSelector(false)}
            >
                <IoCloseSharp size={60}/>
            </div>
            <div>
                {showAnaheimResortButton() && (
                    <button
                        onClick={() => {
                            updateDeviceView('RTV__Anaheim');
                            setShowResortSelector(false);
                        }}
                        style={{
                            marginLeft: '10px',
                            border: '2px solid blue',
                            background: 'transparent',
                            color: 'blue',
                            fontFamily: 'Lato',
                            fontSize: '40px',
                            padding: '0px 20px',
                            borderRadius: '20px',
                            fontWeight: '700',
                            margin: '10px 0px',
                            cursor: 'pointer',
                        }}
                    >
                        Anaheim Resort
                    </button>
                )}
            </div>
            <div>
                {showOrlandoResortButton() && (
                    <button
                        onClick={() => {
                            updateDeviceView('RTV__Orlando');
                            setShowResortSelector(false);
                        }}
                        style={{
                            marginLeft: '10px',
                            border: '2px solid blue',
                            background: 'transparent',
                            color: 'blue',
                            fontFamily: 'Lato',
                            fontSize: '40px',
                            padding: '0px 20px',
                            borderRadius: '20px',
                            fontWeight: '700',
                            margin: '10px 0px',
                            cursor: 'pointer',
                        }}
                    >
                        Orlando Resort
                    </button>
                )}
                {loading && (
                    <button
                        style={{
                            marginLeft: '10px',
                            border: 'none',
                            background: 'transparent',
                            color: 'blue',
                            fontFamily: 'Lato',
                            fontSize: '40px',
                            padding: '0px 20px',
                            borderRadius: '20px',
                            fontWeight: '700',
                            margin: '10px 0px',
                            cursor: 'pointer',
                        }}
                    >
                        Loading...
                    </button>
                )}
            </div>
        </div>
    );
};

export default RtvSelector;