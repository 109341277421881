import { IoCloseSharp } from "react-icons/io5";
import axios from "../../config/axios";
import live_display_socket from "../../config/live_display_socket";
import { useEffect, useState } from "react";

const RideChangeModal = ({device, setShowRideChange, currentRide}) => {
    const [rides, setRides] = useState([]);

    useEffect(() => {
        axios.get('/rides?parkId=' + currentRide._id.split('_')[0]).then(response=>{
            // console.log(response.data);
            setRides(response.data.rides);
        })
    }, []);


    const updateDeviceView = (ride_id) => {

        setShowRideChange(false);
        axios
            .patch(`/users/device/${device._id}`, {screen_view: `Ride__${ride_id}`}, {withCredentials: true})
            .then(() => {
                live_display_socket.emit('remote_refresh', {
                    device_id: device?._id,
                });
            })
            .catch((error) => {

            });
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '0%',
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
            zIndex: 50,
            opacity: '80%',
        }}>
            <div style={{position: 'absolute', top: '5%', right: '3%', }}
                 onClick={() => setShowRideChange(false)}>
                <IoCloseSharp size={60}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', height: '80vh', width: '80vw', overflowY: 'scroll', marginBottom: '50px'}}>
                {
                rides.map((ride, index) => {
                    return <div
                        key={index}
                        style={{
                            marginLeft: '10px',
                            border: '2px solid blue',
                            background: 'transparent',
                            color: 'white',
                            fontFamily: 'Lato',
                            fontSize: '40px',
                            padding: '0px 20px',
                            borderRadius: '20px',
                            fontWeight: '700',
                            margin: '10px 0px',
                            cursor: 'pointer'
                        }}
                        onClick={() => updateDeviceView(ride._id)}
                    >
                              <span style={{
                                  fontFamily: 'Lato',
                                  fontWeight: '700',
                                  color: 'blue'
                              }}>{ride.name}</span>
                    </div>
                })
            }
            </div>
        </div>
    );
};

export default RideChangeModal;