import React from 'react';

const RespMessage = ({msg}) => {
    return (
        <div>
            {
                msg === 'Ride Closed' ? <span style={{fontSize: '28px'}}>Ride <br/> Closed</span> : null
            }
            {
                msg === 'No Wait' ? <span style={{fontSize: '28px'}}>No <br/> Wait</span> : null
            }
        </div>
    );
};

export default RespMessage;