import axios from 'axios';
import { serverUrl } from "./serverConfig";

let baseUrl;


baseUrl = `${serverUrl}/api`;

//creating an axios instance with auth setup
const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 40000,
});

axiosInstance.interceptors.request.use((config) => {
    if (config.withCredentials) {
        const deviceId = localStorage.getItem('token');
        config.headers['accesstoken'] = `${deviceId}`;
    }

    return config;
});

//defining the interceptor which will automate the process of refresh and access token
axiosInstance.interceptors.response.use(
    function (response) {
        // Simply returning the response
        return response;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default axiosInstance;
