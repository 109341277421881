import { io } from 'socket.io-client';
import { serverUrl } from "./serverConfig";

let live_display_socket;

live_display_socket = io(`${serverUrl}/live-display`);
// live_display_socket = io('https://c9-dev-server-ws.codenine.tech/live-display');
// live_display_socket = io('http://localhost:5000/live-display');


export default live_display_socket;
