import { useState } from "react";
import Settings from "./Settings";
import moment from "moment-timezone";

const Home = ({device, countDownDate, timezone, currentDate}) => {

    const [showSettings, setShowSettings] = useState(false);

    const getDaysLeft = () => {
        const countdownEnd = moment(countDownDate).tz(timezone);
        const current = moment(currentDate).tz(timezone);
        // console.log(countdownEnd?.diff(current, 'days'))
        return countdownEnd?.diff(current, 'days');
    };


    return (
        <div style={{width: '100vw', height: '100vh', position: 'relative'}}>
            <img src="/static/frame-top.png" alt=""
                 style={{position: 'fixed', top: '0', left: '0', width: '100vw', zIndex: 10}}/>

            <img src="/static/logo-white.png" alt="" style={{
                zIndex: 20,
                position: 'fixed',
                top: '2%',
                height: '100px',
                left: '50%',
                transform: 'translateX(-50%)'
            }}/>

            <img src="https://img.icons8.com/ios-filled/500/FFFFFF/settings.png"
                 alt="settings" style={{
                zIndex: 20,
                position: 'fixed',
                top: '2%',
                height: '50px',
                right: '0%',
                transform: 'translateX(-50%)'
            }}
                 onClick={() => setShowSettings(true)}
            />

            <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: '#12243F',
                width: '90vw'
            }}>
                <span style={{fontFamily: 'Lato', fontSize: '50px', fontWeight: '400'}}>WELCOME</span>
                <span style={{
                    fontFamily: 'Lato',
                    fontWeight: '700',
                    fontSize: '80px',
                }}>{device.subscription.last_name} Family</span>
            </div>


            {
                getDaysLeft() >= 0 &&
                <div style={{
                    position: 'fixed',
                    top: '68%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100vw',
                    color: '#12243F',
                    fontFamily: 'Lato',
                }}>
                <span
                    style={{fontFamily: 'Lato', fontSize: '45px', fontWeight: '600'}}>Check-In: {moment(countDownDate).format('MMMM DD, YYYY')}</span>
                </div>
            }

            {/*<BottomBar screen={'home'} device={device}/>*/}

            {
                showSettings && <Settings device={device} setShowSettings={setShowSettings}/>
            }

        </div>
    );
};

export default Home;
