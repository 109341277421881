import { useEffect, useState } from 'react';
import axios from '../../config/axios.js';
import { handleAPIError } from '../../utils/helpers.js';
import SingleRideWaitTime from './SingleRideWaitTime.jsx';
import live_display_socket from '../../config/live_display_socket.js';

const ScrollRideWaitTime = ({device, setCurrentRide}) => {
    const [rides, setRides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentRideIndex, setCurrentRideIndex] = useState(null);
    const [autoScrollInterval, setAutoScrollInterval] = useState(device.auto_scroll_interval || 10);
    const [disableAutoScroll, setDisableAutoScroll] = useState(false);


    useEffect(() => {
        if(currentRideIndex && rides) {
            setCurrentRide(rides[currentRideIndex]._id);
        }
    }, [currentRideIndex, rides]);

    useEffect(() => {
        try {
            axios
                .get('/rides/all/' + device._id)
                .then((response) => {
                    // console.log(response.data.rides);
                    setRides(response.data.rides);
                    setLoading(false);
                })
                .catch((error) => {
                    handleAPIError(error);
                })
        } catch (error) {
            // console.log('This is error: ', error)
        }
    }, []);

    useEffect(() => {
        live_display_socket.on('display_update_device', (data) => {
            setAutoScrollInterval(data.auto_scroll_interval);
        });

        live_display_socket.on('display_disable_auto_scroll', () => {
            setDisableAutoScroll(true);
        });
    }, []);

    useEffect(() => {
        if (rides && disableAutoScroll) {
            // console.log(currentRideIndex);
            const screen_view = 'Ride__' + rides[currentRideIndex]._id;
            axios
                .patch('/users/device/'+device._id, {screen_view: screen_view}, {withCredentials: false})
                .then(() => {
                    live_display_socket.emit('remote_refresh', {
                        device_id: device?._id,
                    });
                })
                .catch((error) => {
                    handleAPIError(error);
                });
        }
    }, [currentRideIndex, rides, disableAutoScroll]);

    const findRideIndexById = (rides, rideId) => {
        // console.log(rides, rideId);
        for (let i = 0; i < rides.length; i++) {
            if (rides[i]._id === rideId) {
                return i; // Return the index of the matched ride
            }
        }
        return -1; // Return -1 if no match found
    };

    useEffect(() => {
        if(rides.length>0) {
            if (device.auto_scroll_start_ride) {
                const start_ride_id = device.auto_scroll_start_ride ? device.auto_scroll_start_ride.split('__')[1] : 'Ride__' + rides[0]._id;
                // console.log('start ride id: ', device.auto_scroll_start_ride);
                const index = findRideIndexById(rides, start_ride_id);
                setCurrentRide(rides[index > -1 ? index : 0]._id);
                setCurrentRideIndex(index > -1 ? index : 0);
            }
        }
    }, [rides]);

    useEffect(() => {
        // console.log('current ride index: ', currentRideIndex, rides.length, autoScrollInterval);
        if (rides.length > 0 && currentRideIndex !== null) {
            const interval = setInterval(() => {
                // console.log('interval trig');
                setCurrentRideIndex((prevIndex) => (prevIndex + 1) % rides.length);
            }, autoScrollInterval * 1000);

            return () => clearInterval(interval); // cleanup on component unmount
        }
    }, [rides, autoScrollInterval, currentRideIndex]);

    return (
        <>
            {/*{loading ? (*/}
            {/*    <div>Loading</div>*/}
            {/*) : (*/}
            {/*    <SingleRideWaitTime*/}
            {/*        ride_id={rides[currentRideIndex]._id}*/}
            {/*        device_id={device._id}*/}
            {/*    />*/}
            {/*)}*/}
        </>
    );
};

export default ScrollRideWaitTime;
