const GetStarted = ({setStarted}) => {

    return (
        <div
            style={{
                display: 'flex',
                height: '100vh',
                width: '100vw',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
                position: 'relative'
            }}
            onClick={() => setStarted(true)}
        >
            <img
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: '0',
                    opacity: 1,
                    transition: 'opacity 2s',
                }}
                src="/static/connection-2.png"
                alt=""
            />
            <img
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: '2',
                    opacity: 1,
                }}
                src="/static/connection-bg.png"
                alt=""
            />
        </div>
    );
};

export default GetStarted;
