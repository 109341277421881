import React, { useEffect, useState } from "react";
import BottomBar from "./BottomBar";
import moment from 'moment-timezone';
import axios from '../../config/axios';

const park_list = [
    {
        park_name: 'Magic Kingdom',
        park_code: 'MK',
    },
    {
        park_name: 'Hollywood Studios',
        park_code: 'HS',
    },
    {
        park_name: 'Animal Kingdom',
        park_code: 'AK',
    },
    {
        park_name: 'Disneyland',
        park_code: 'DL',
    },
    {
        park_name: 'California Adventure',
        park_code: 'CA',
    },
    {
        park_name: 'EPCOT',
        park_code: 'EPCOT',
    },
]

const AnaheimResortTv = ({device, userTz}) => {

    const [park, setPark] = useState(0);
    const [parkHours, setParkHours] = useState(null);
    const [timezone, setTimezone] = useState(null);
    const [parkTimezone, setParkTimezone] = useState('EST5EDT');
    const [lt, setLt] = useState(null);
    const [date, setDate] = useState(null);

    const [hideTime, setHideTime] = useState(true);
    const [operatingHour, setOperatingHour] = useState(null);
    const [earlyEntry, setEarlyEntry] = useState(null);
    const [extendedEvening, setExtendedEvening] = useState(null);


    useEffect(() => {
        let park_code = park_list[park].park_code;
        if (park_code === 'EPCOT') {
            park_code = 'EP';
        }

        if (!parkHours) {
            setOperatingHour(null);
            return;
        }

        // handle park timezones
        if (park_code === 'MK' || park_code === 'EP' || park_code === 'HS' || park_code === 'AK') {
            setParkTimezone('EST5EDT');
        } else {
            setParkTimezone('PST8PDT');
        }

        parkHours.forEach(park => {
            if (park._id === park_code) {
                if (park.schedule.length > 0) {
                    setOperatingHour(park.schedule[0])

                    let operating = null;
                    let earlyEntry = null;
                    let extendedEvening = null;

                    // console.log(park.schedule[0].schedule)

                    park.schedule[0].schedule.forEach(item => {

                        const schedule = item;

                        if (schedule.type === 'OPERATING') {
                            operating = schedule;
                            // console.log(schedule)
                        }

                        if (schedule.description === 'Early Entry') {
                            earlyEntry = schedule;
                        }

                        if (schedule.description === 'Extended Evening') {
                            extendedEvening = schedule;
                        }
                    });

                    setOperatingHour(operating);
                    setEarlyEntry(earlyEntry);
                    setExtendedEvening(extendedEvening);
                }
            }
        });

    }, [park, parkHours]);

    useEffect(() => {
        const video = document.getElementById('video');

        const updatePark = () => {
            const currentTime = video.currentTime;

            // console.log(parseInt(currentTime))

            if (parseInt(currentTime) === 0) {
                setHideTime(true);
            } else if (parseInt(currentTime) === 10) {
                setHideTime(true);
            }

            if (parseInt(currentTime) === 2) {
                setHideTime(false);
            } else if (parseInt(currentTime) === 14) {
                setHideTime(false);
            }

            if (currentTime >= 0 && currentTime < 11) {
                setPark(3); // Magic Kingdom
            } else if (currentTime >= 11 && currentTime < 24) {
                setPark(4); // EPCOT
            }
        };

        const intervalId = setInterval(updatePark, 500); // Check every 0.5 seconds

        video.play();

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);


    const handleDate = () => {
        if (device.timezone) {
            let tz = device.timezone.split('-')[0];
            if (tz === 'EST' || tz === 'EDT') {
                tz = 'EST5EDT';
            } else if (tz === 'PST' || tz === 'PDT') {
                tz = 'PST8PDT';
            } else if (tz === 'CST') {
                tz = 'CST6CDT';
            } else if (tz === 'MST') {
                tz = 'MST7MDT'
            } else if (tz === 'AKST') {
                tz = 'America/Anchorage';
                setTimezone(tz);
                setLt(moment().tz(tz).subtract(1, 'hour').format('LT'));
                setDate(moment().tz(tz).format('dddd, MMM D, Y'));
                return;
            } else if (tz === 'my timezone') {
                if (!userTz) {
                    return;
                } else {
                    tz = userTz;
                    if (tz === 'AKST') {
                        tz = 'America/Anchorage';
                        setTimezone(tz);
                        setLt(moment().tz(tz).subtract(1, 'hour').format('LT'));
                        setDate(moment().tz(tz).format('dddd, MMM D, Y'));
                        return;
                    }
                }
            }

            setTimezone(tz);
            setLt(moment().tz(tz).format('LT'));
            setDate(moment().tz(tz).format('dddd, MMM D, Y'));
        }
    }

    useEffect(() => {
        const dateInterval = setInterval(() => {
            handleDate();
        }, 40000);
        handleDate();

        return () => clearInterval(dateInterval);
    }, [device, userTz]);


    useEffect(() => {
        // GET park hours
        axios.get('/parks/hours').then(response => {
            setParkHours(response.data.parks);
        });
    }, []);

    const dividerStyle = {
        content: "",
        height: '2px',
        width: '400px',
        background: 'white',
        marginTop: '15px',
        marginBottom: '15px'
    }


    return (
        <div style={{height: '100vh', width: '100vw', position: 'relative'}}>
            <div style={{
                height: '14vh',
                position: 'absolute',
                top: '0%',
                background: '#0F233F',
                left: '0',
                width: '100vw'
            }}/>
            <video
                style={{height: '100vh', width: '100vw', objectFit: 'cover'}}
                id="video"
                src="https://wait-signs-static.s3.amazonaws.com/Hotel+Screen_DL%26CA-compressed.mp4"
                muted={true}
                autoPlay={true}
                controls={false}
                loop={true}
            ></video>

            <div style={{
                zIndex: 30,
                position: "fixed",
                top: '10px',
                color: 'white',
                width: '100vw',
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '2%',
                paddingRight: '2%',
                fontFamily: 'Lato',
            }}>
                <div style={{fontSize: '35px', display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight: '300'}}>WELCOME</span>
                    <span style={{marginTop: '-10px', fontWeight: '700'}}>{device.subscription.last_name} Family</span>
                </div>
                {
                    timezone &&
                    <div style={{display: 'flex', flexDirection: 'column', fontSize: '40px', alignItems: 'flex-end'}}>
                        <span style={{fontWeight: '800'}}>{lt}</span>
                        <span style={{fontWeight: '300', marginTop: '-15px', fontSize: '30px'}}>{date} </span>
                    </div>
                }
            </div>

            {
                (operatingHour && !hideTime) &&
                <div style={{
                    position: 'absolute',
                    top: '30%',
                    right: '30%',
                    color: 'white',
                    fontFamily: 'Lato',
                    fontSize: '30px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={{fontSize: '40px'}}>Today's Hours</span>
                    <span>{moment(operatingHour?.openingTime).tz(parkTimezone).format('LT')} - {moment(operatingHour?.closingTime).tz(parkTimezone).format('LT')}</span>

                    {
                        earlyEntry &&
                        <>
                            <div style={dividerStyle}></div>
                            <span style={{fontSize: '40px'}}>Early Entry</span>
                            <span>{moment(earlyEntry.openingTime).tz(parkTimezone).format('LT')} - {moment(earlyEntry.closingTime).tz(parkTimezone).format('LT')}</span>
                        </>
                    }

                    {
                        extendedEvening &&
                        <>
                            <div style={dividerStyle}></div>
                            <span style={{fontSize: '40px'}}>Special Event</span>
                            <span>{moment(extendedEvening.openingTime).tz(parkTimezone).format('LT')} - {moment(extendedEvening.closingTime).tz(parkTimezone).format('LT')}</span>
                        </>
                    }

                </div>
            }

        </div>
    );
};

export default AnaheimResortTv;
